import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`


  @media (min-width: ${breakpoints.md}){
    min-height: 484px;
  }

  @media (min-width: ${breakpoints.lg}){
    min-height: 502px;
  }

  @media (min-width: ${breakpoints.xl}){
    min-height: 508px;
  }


`
export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFB46E;
  border-radius: 8px;
  margin-right: 16px;
`
export const BlogItem = styled.div`
  font-family: "Inter", Helvetica, sans-serif;
  margin: 0 0 2.5rem;

  img {
    width: 100%;
    border-radius: 1rem;
  }

  header {
    font-family: "Sora", Helvetica, sans-serif;
    font-size: 16px;
    line-height: 19px;
    margin: 1.5rem 0 0.5rem;
    font-weight: 600;
    color: #161616;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 1rem;
    color: #161616;
  }

  footer {
    font-size: 12px;
    line-height: 15px;
    color: #6A6C72;
  }
`
